
import Page from '@root/common/base/Page';
import { Section } from '@root/modules/frontpage/types/frontpage';
import FeedParser from '@root/common/components/helpers/FeedParser.vue';
import GridSkeletonLoader from '@root/common/components/base/loader/GridSkeletonLoader.vue';
import { feedService } from '@root/modules/frontpage/services/feed.service';
import { pageContentService } from '@root/modules/feed/services/pageContent.service';
import getFrontPageAdsSettings from '@root/modules/frontpage/utils/getFrontPageAdsSettings';
import getChannelDomain from '@root/modules/channel/utils/getChannelDomain';
import rootConfig from '@root/config/root.config';
import { Pager } from '@root/common/types/fragment';

interface Data {
  feed: Section;
  type: string;
  pager: Pager;
  loadingSections: boolean;
  limit: number;
}

interface FeedVariables {
  id?: number | string;
  domain?: string;
  language: string;
  offset?: number;
}

interface Methods {
  loadSections: () => void;
}

export default Page.extend<Data, Methods, unknown, unknown>({
  components: {
    FeedParser,
    GridSkeletonLoader,
  },
  beforeRouteLeave(to, from, next) {
    this.resetPageAdsSettings();
    next();
  },
  data() {
    return {
      feed: [],
      pager: {},
      type: 'default',
      loadingSections: false,
      limit: 10,
    };
  },
  async fetch() {
    try {
      // Set frontpage type, if defined in config
      const { settings } = this.$channelConfig('page').frontpage;
      if (settings && settings.type) {
        this.type = settings.type;
      }

      const { domain, apiFeed, lang } = this.$channelConfig('settings');

      // Validate domain
      const validDomain = getChannelDomain(this.$route.params.channel || rootConfig.main_slug);
      if (!validDomain && this.$route.params.channel !== 'receptai') {
        this.handlePageError({ message: 'error.page_not_found', statusCode: 404 });
        return;
      }

      if (apiFeed) {
        const variables: FeedVariables = { language: lang.toUpperCase() };

        const id = settings && settings.id;
        if (id) {
          variables.id = id;
        } else {
          variables.domain = domain;
        }

        const response = await pageContentService.fetch(variables);

        // Fetch feed in SSR
        // Fetch feed in CSR or fallback to the store data
        this.feed = response?.feed || this.$store.getters['frontpage/getFeedData'];
        this.pager = response?.pager || this.$store.getters['frontpage/getPagerData'];

        this.$store.commit('frontpage/setFeedData', this.feed);
        this.$store.commit('frontpage/setPagerData', this.pager);
      } else {
        const feed = (await feedService.fetch({ domain }))?.feed;
        // Fetch feed in SSR
        // Fetch feed in CSR or fallback to the store data
        this.feed = feed || this.$store.getters['frontpage/getFeedData'];
        this.$store.commit('frontpage/setFeedData', this.feed);
      }

      this.setPageAdsSettings(getFrontPageAdsSettings({ $channelConfig: this.$channelConfig }));
    } catch (e) {
      this.handlePageError(e);
    }
  },
  computed: {
    refetchHeadlines() {
      return this.$store.state.frontpage.refetch;
    },
  },
  watch: {
    refetchHeadlines(refetch) {
      if (refetch) {
        window.scrollTo(0, 0);
        this.$fetch();
        this.$store.commit('frontpage/setRefetchState', false);
      }
    },
  },
  head() {
    const settings = this.$channelConfig('meta').base;
    const meta = this.getPageMeta();
    meta.setTitle({ title: settings.title });

    return {
      title: meta.title,
      link: meta.link,
    };
  },
  methods: {
    async loadSections() {
      const d = document.documentElement;
      const w = document.querySelector('.container.default,.container.channel') as HTMLElement;
      if (w) {
        const scrollOffset = d.clientHeight + screen.height * 1.5;

        const userHasScrolled = window.scrollY > 200;
        const offset = window.scrollY + scrollOffset;
        const height = w.offsetHeight;

        if (userHasScrolled && offset >= height && !this.loadingSections) {
          this.loadingSections = true;

          const { settings } = this.$channelConfig('page').frontpage;
          const { domain, lang } = this.$channelConfig('settings');

          const variables: FeedVariables = { language: lang.toUpperCase() };

          const id = settings && settings.id;
          if (id) {
            variables.id = id;
          } else {
            variables.domain = domain;
          }

          variables.offset = !this.pager.offset ? this.limit : this.pager.offset + this.limit;

          const response = await pageContentService.fetch(variables);
          const sections = response?.feed || [];

          this.feed = [...this.feed, ...sections];
          this.pager = response?.pager || {};

          // Feed end reached set remove scroll event listener
          if (!sections.length) {
            window.removeEventListener('scroll', this.loadSections);
          }

          this.loadingSections = false;
        }
      }
    },
  },
  mounted() {
    this.restoreScroll();
    window.addEventListener('scroll', this.loadSections);
  },
  destroyed() {
    window.removeEventListener('scroll', this.loadSections);
  },
});

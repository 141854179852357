import Service from '@ekd/lt-portal-connector/dist/Service';
import { getEnv } from '../../../common/utils/getEnv';
import { dataLoader, type RedisDataLoaderOptions } from '@root/libs/redis/dataloader/dataLoader';
import { AxiosRequestConfig } from 'axios';

type ServiceVariables = {
  domain: string;
};

class FeedService extends Service {
  /**
   * Fetch feed
   */
  public async fetch({ domain }: { domain: string }) {
    try {
      const dataLoaderOptions: RedisDataLoaderOptions = {
        local: {
          cache: true,
        },
        remote: {
          expireTimeMs: domain === 'www.delfi.lt' ? 15000 : 60000, // 15 seconds for Delfi FP, 60 seconds for channels
          gracePeriodMs: 24 * 3600 * 1000, // 24 hours
          keyPrefix: 'channel_feed',
        },
        cacheKeyFn: () => {
          return `site_${domain}`;
        },
      };

      const options = {
        url: getEnv('feedApi'),
        params: {
          ch: domain,
        },
        timeout: 3000,
      };

      const requestWrapper = (options: AxiosRequestConfig) => {
        const apiProvider = this.createProvider('HTTP');
        return apiProvider.request<Response>(options);
      };

      const redisDataLoader = dataLoader<AxiosRequestConfig, Response>(requestWrapper, dataLoaderOptions);
      const feed: Response = redisDataLoader ? await redisDataLoader.load(options) : await requestWrapper(options);

      if (!feed) {
        return null;
      }

      return { domain, feed };
    } catch {
      return null;
    }
  }
}

export const feedService = new FeedService();

export function fetchFrontpageService(variables: ServiceVariables, { req }: { req: PortalRootIncomingMessage }) {
  const feedService = new FeedService({ req });
  return feedService.fetch(variables);
}

import { ContentFragment } from '@root/modules/article/types/article';

// Check if ONE value matches against validator.
export function oneOf(value: string, validList: string[]) {
  for (let i = 0; i < validList.length; i++) {
    if (value === validList[i]) {
      return true;
    }
  }
  return false;
}
export function getAttributeByName(attributes: Record<string, any> | ContentFragment[] | null, position: string) {
  if (!attributes) {
    return false;
  }
  const attrs = attributes.find(({ name }: any) => name === position)?.value || null;
  return attrs;
}

export function isUndefined(value: unknown) {
  return typeof value === 'undefined';
}

export function convertArrayToObject<T>(value: unknown, defaultObject?: Record<string, unknown>) {
  if (!Array.isArray(value)) {
    return (defaultObject || {}) as T;
  }

  return (value as { key: string; value: string }[])?.reduce((map, { key, value }) => {
    return { ...map, [key]: value };
  }, (defaultObject || {}) as T);
}

export function isObjectEmpty(obj: Record<string, unknown>) {
  // eslint-disable-next-line no-unreachable-loop
  for (const i in obj) return false;
  return true;
}
/**
 * Check if app is server side rendered. This is an alterative method for server side rendering checks.
 * In nuxt server middleware process.server is undefined
 */
export function isServer() {
  return typeof window === 'undefined' || process.server;
}

export function isValidSignedInt32(x: number) {
  return Boolean(x >>> 0 === x);
}

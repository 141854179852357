import { GetChannelConfigFunction } from '@root/libs/init-channel/types';

export default function getFrontPageAdsSettings({ $channelConfig }: { $channelConfig: GetChannelConfigFunction }) {
  const { id } = $channelConfig('settings');
  const { adForm } = $channelConfig('ads').component;

  // Create keywords data
  const keywords = [`${id}`];

  if (id === 'delfi') {
    keywords.push('front');
  }

  const keyValue = [`www.delfi.lt:${id === 'delfi' ? 'front' : id}`];

  const placements: Record<string, unknown> = adForm.placements || {};
  const disableAds = adForm.disableAds || [];

  return {
    mkw: keywords,
    mkv: keyValue,
    placements,
    disableAds,
  };
}
